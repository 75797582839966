import http from "../../utils/http"

/**
 * 获取用户信息
 * @param {object} params
 */
export function company(params) {
    return http({
        url: "/api/member/getverify",
        data: params,
        forceLogin: true
    })
}
/**
 * 修改昵称
 * @param {object} params
 */
export function saveVerify(params) {
    return http({
        url: "/api/member/saveverify",
        data: params,
        forceLogin: true
    })
}