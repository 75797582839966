<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<div class="member-info" v-loading="loading">
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="认证信息" name="first">
					<el-form ref="companyRef" :model="memberInfo" :rules="infoRules" label-width="120px">
						<el-form-item label="企业名称：" prop="company_name" required>
							<p v-if="memberInfo.verify_status==1">{{ memberInfo.company_name }}</p>
							<p v-else><el-input v-model="memberInfo.company_name" placeholder="请输入营业执照上的公司名称"></el-input></p>
						</el-form-item>
						<el-form-item label="企业法人：" prop="realname" required>
							<p v-if="memberInfo.verify_status==1">{{ memberInfo.realname }}</p>
							<p v-else><el-input v-model="memberInfo.realname" placeholder="请输入营业执照上的企业法人姓名"></el-input></p>
						</el-form-item>
						<el-form-item label="联系姓名：" prop="company_contact" required>
							<p v-if="memberInfo.verify_status==1">{{ memberInfo.company_contact }}</p>
							<p v-else><el-input v-model="memberInfo.company_contact" placeholder="请输入真实姓名"></el-input></p>
						</el-form-item>
						<el-form-item label="上传营业执照：" required>
							<p>请上传营业执照图片　<a href="#" style="color:#507DAF;">查看上传示例</a></p>
						</el-form-item>
						<el-upload v-if="memberInfo.verify_status!=1" :action="uploadActionUrl" :show-file-list="false" :on-success="handleAvatarSuccess" class="upload">
							<div class="img-wrap"><img :src="$img(memberInfo.company_licence)" @error="memberInfo.company_licence = uploadLicenceImg" /></div>
						</el-upload>
						<div v-if="memberInfo.verify_status==1" class="upload">
							<div class="img-wrap"><img :src="$img(memberInfo.company_licence)" @error="memberInfo.company_licence = uploadLicenceImg" /></div>
						</div>
					</el-form>
					<div class="btn"><el-button v-if="memberInfo.verify_status!=1" size="medium" type="primary" @click="saveInfo">提交</el-button></div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import { company, saveVerify } from '@/api/member/verify';
import Config from '@/utils/config';
export default {
	name: 'company',
	components: {},
	data: () => {
		return {
			uploadLicenceImg:'upload/default/default_img/licence.png',
			memberInfo: {
				company_name: '',
				realname: '', //法人
				company_contact: '', //联系人
				company_licence: '',
				verify_status: 0,
			},
			infoRules: {
				company_name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }, { max: 30, message: '最大长度为30个字符', trigger: 'blur' }],
				realname: [{ required: true, message: '请输入企业法人', trigger: 'blur' }, { max: 10, message: '最大长度为10个字符', trigger: 'blur' }],
				company_contact: [{ required: true, message: '请输入联系姓名', trigger: 'blur' }, { max: 10, message: '最大长度为10个字符', trigger: 'blur' }]
			},
			activeName: 'first',
			loading: true,
			uploadActionUrl: Config.baseUrl + '/api/upload/info',
			imgUrl: '',
			yes: true
		};
	},
	created() {
		this.getInfo();
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		getInfo() {
			company()
				.then(res => {
					if (res.code == 0 && res.data!=null) {
						this.memberInfo.company_name = res.data.company_name;
						this.memberInfo.realname = res.data.realname;
						this.memberInfo.company_contact = res.data.company_contact;
						this.memberInfo.company_licence = res.data.company_licence;
						this.memberInfo.verify_status = res.data.verify_status;
					}
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error(err.message);
				});
		},
		handleClick(tab, event) {},
		saveInfo() {
			this.$refs.companyRef.validate(valid => {
				if (valid) {
					if(this.memberInfo.company_licence.lastIndexOf('licence.png')>1){
						this.$message.error('营业执照必须上传！');
						return false;
					}
					saveVerify({ 
						company_name: this.memberInfo.company_name, 
						realname: this.memberInfo.realname,
						company_contact: this.memberInfo.company_contact,
						company_licence: this.memberInfo.company_licence
						})
						.then(res => {
							if (res.code == 0) {
								this.$message({ message: '认证信息保存成功', type: 'success' });
								this.getInfo;
								this.$store.dispatch('member/member_detail', { refresh: 1 });
							}
						})
						.catch(err => {
							this.$message.error(err.message);
						});
				} else {
					return false;
				}
			});
		},
		handleAvatarSuccess(res, file) {
			this.imgUrl = res.data.pic_path;
			this.memberInfo.company_licence = res.data.pic_path;
		}
	}
};
</script>
<style>
.member-info .el-upload {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}
</style>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.member-info {
	background: #ffffff;
	padding: 20px;
	.el-tab-pane {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		.preview {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			.title {
				margin-right: 20px;
				line-height: 3;
			}
			.content {
				color: $base-color;
				line-height: 1.5;
			}
		}
		.upload {
			display: flex;
			justify-content: left;
			margin-left:120px;
		}
		.el-upload {
			width: 120px;
		}
		.img-wrap {
			width: 146px;
			min-height: 120px;
			display: block;
			min-line-height: 120px;
			overflow: hidden;
			border: 1px solid #f1f1f1;
			cursor: pointer;
			img {
				display: inline-block;
			}
		}
		.el-form {
			margin-top: 20px;
			width: 500px;
			margin-left: 200px;
			.toBind {
				cursor: pointer;
				&:hover {
					color: $base-color;
				}
			}
		}
		.btn {
			text-align: center;margin-top:15px;margin-bottom:15px;
		}
	}
}
</style>
